




























































import IgnoreEventPopup from '@/modules/events/components/events-manager/view-edit-popup/ignore-event.popup.vue';
import IDayEvent from '@/modules/events/interfaces/day-event.interface';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { ValidationError } from 'class-validator';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import DeleteEventPopup from '@/modules/events/components/events-manager/view-edit-popup/delete-event.popup.vue';
import ValidationErrorComponent from '@/modules/common/components/ui-kit/validation-error.vue';

@Component({
    components: {
        DeleteEventPopup,
        IgnoreEventPopup,
        ValidationErrorComponent,
    },
    filters: {
        NumberOfGuestsFilter: (value: string | number | null) => {
            if (!value) {
                return '-';
            }
            if (Number(value) <= 0) {
                return '-';
            }
            return value;
        },
    },
})
export default class ViewEventForm extends Vue {
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @Inject(UserServiceS) private userService!: UserService;

    @Prop({ required: false })
    private eventId?: string;

    isDeleting: boolean = false;
    isIgnoring: boolean = false;

    validationErrors: ValidationError[] | null = null;

    get currentHotelId() {
        return this.userService.currentHotelId;
    }

    get isOwnerCurrentHotel() {
        if (this.event) {
            return this.event.event.ownerId === this.currentHotelId;
        }
        return false;
    }

    get isMyEvent(): boolean {
        if (this.event && this.userService.user) {
            const { event } = this.event;
            if (this.userService.isChainUser || this.userService.isClusterUser) {
                return this.userService.chainNumber === event.ownerId;
            }
            return event.ownerId === this.currentHotelId;
        }
        return false;
    }

    get event(): IDayEvent | null {
        if (this.eventId) {
            return this.eventsManagerService.getDayEventById(this.eventId);
        }
        return null;
    }

    formatDate(date: string) {
        const d = new Date(date);
        const dayName = d.toLocaleDateString('en-US', { weekday: 'short', timeZone: 'UTC' });
        const day = (`0${d.getUTCDate()}`).slice(-2);
        const month = (`0${d.getUTCMonth() + 1}`).slice(-2);
        return `${dayName} ${day}/${month}/${d.getUTCFullYear()}`;
    }

    editEvent() {
        this.$router.push({
            name: this.$route.name!.replace('view', 'edit'),
            params: {
                ...this.$route.params,
                eventId: String(this.eventId),
            },
        });
    }

    async approve() {
        if (this.eventId) {
            const errors = await this.eventsManagerService.approveEvent(this.eventId);
            if (errors.length) {
                this.validationErrors = [...errors];
            } else {
                this.close();
            }
        }
    }

    ignore() {
        // TODO: implement logic when backend will be ready
    }

    toggleDeleteEventPopup(): void {
        this.isDeleting = !this.isDeleting;
    }

    toggleIgnoreEventPopup(): void {
        this.isIgnoring = !this.isIgnoring;
    }

    close() {
        this.$emit('close');
    }
}






import { Component, Vue, Prop } from 'vue-property-decorator';
import ViewEditEventPopup from '@/modules/events/components/events-manager/view-edit-popup/view-edit-event-popup.vue';

@Component({
    components: { ViewEditEventPopup },
})
export default class ViewEditEventModalPage extends Vue {
    @Prop({ required: false })
    private eventId?: string;
}


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { ValidationError } from 'class-validator';
// @ts-ignore
import ModalWrapper, { DialogOptions } from '@/modules/common/components/modal-wrapper.vue';
import ViewEventTitle from '@/modules/events/components/events-manager/view-edit-popup/view-event-title.vue';
import ValidationErrorComponent from '@/modules/common/components/ui-kit/validation-error.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component({
    components: {
        ModalWrapper,
        ValidationErrorComponent,
        ViewEventTitle,
    },
})
export default class IgnoreEventPopup extends Vue {
    @Inject(EventsManagerServiceS) protected eventsManagerService!: EventsManagerService;
    @Inject(UserServiceS) protected userService!: UserService;

    @Prop({ required: true })
    private eventId!: string;

    @Prop({ required: true })
    private isVisible!: boolean;

    validationErrors: ValidationError[] | null = null;

    async IgnoreEvent(closeModal: Function) {
        const errors = await this.eventsManagerService.ignoreEvent(this.eventId);
        if (errors.length) {
            this.validationErrors = [...errors];
        } else {
            this.$emit('onIgnore');
            closeModal();
        }
    }
}
